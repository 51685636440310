import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { IActionResult, IAppState } from "../../redux/storeTypes";
import { WebApplication, WebPage, WebPageEnum } from "../../models/PaymentChannelWebApplication";
import { Content, StatusEnum } from "../../models/CMS";
import type { Cell, Row, Value } from '@react-page/editor';
import Editor, { CellPlugin } from '@react-page/editor';

import image from '@react-page/plugins-image';
import { getContentAction } from "../../redux/actions/cms";
import { Routes } from "../../routes";
import CardPlugin from "../paymentChannels/web/components/plugins/CardPlugin";
import ReleaseCardPlugin from "../paymentChannels/web/components/plugins/ReleaseCardPlugin";
import HeaderPlugin from "../paymentChannels/web/components/plugins/HeaderPlugin";
import HorizontalRulePlugin from "../paymentChannels/web/components/plugins/HorizontalRulePlugin";
import PlainTextPlugin from "../paymentChannels/web/components/plugins/PlainTextPlugin";
import NavigationLinkPlugin from "../paymentChannels/web/components/plugins/NavigationLinkPlugin";
import AccountDetailsPlugin from "../paymentChannels/web/components/plugins/AccountDetailsPlugin";
import BillingDetailsPlugin from "../paymentChannels/web/components/plugins/BillingDetailsPlugin";
import PaymentDetailsPlugin from "../paymentChannels/web/components/plugins/PaymentDetailsPlugin";
import PaymentReferencePlugin from "../paymentChannels/web/components/plugins/PaymentReferencePlugin";
import TylerEaglePaymentReferencePlugin from "../paymentChannels/web/components/plugins/TylerEaglePaymentReferencePlugin";
import PaymentSummaryPlugin from "../paymentChannels/web/components/plugins/PaymentSummaryPlugin";
import MultiSearchPlugin from "../paymentChannels/web/components/plugins/MultiSearchPlugin";
import MultiSearchResultsPlugin from "../paymentChannels/web/components/plugins/MultiSearchResultsPlugin";
import NericSearchResultsPlugin from "../paymentChannels/web/components/plugins/NericResultsPlugin";
import ForsythSearchResultsPlugin from "../paymentChannels/web/components/plugins/ForsythResultsPlugin";
import LarimerSearchResultsPlugin from "../paymentChannels/web/components/plugins/LarimerSearchResultsPlugin";
import LoganSearchResultsPlugin from "../paymentChannels/web/components/plugins/LoganResultsPlugin";
import MathewsSearchResultsPlugin from "../paymentChannels/web/components/plugins/MathewsResultsPlugin";
import StCharlesSearchResultsPlugin from "../paymentChannels/web/components/plugins/StCharlesSearchResultsPlugin";
import PikeSearchResultsPlugin from "../paymentChannels/web/components/plugins/PikeResultsPlugin";
import ItemShoppingCartPlugin from "../paymentChannels/web/components/plugins/ItemShoppingCartPlugin";
import StCharlesShoppingCartPlugin from "../paymentChannels/web/components/plugins/StCharlesShoppingCartPlugin";
import ForsythShoppingCartPlugin from "../paymentChannels/web/components/plugins/ForsythShoppingCartPlugin";
import LoganShoppingCartPlugin from "../paymentChannels/web/components/plugins/LoganShoppingCartPlugin";
import LarimerShoppingCartPlugin from "../paymentChannels/web/components/plugins/LarimerShoppingCartPlugin";
import MathewsShoppingCartPlugin from "../paymentChannels/web/components/plugins/MathewsShoppingCartPlugin";
import PikeShoppingCartPlugin from "../paymentChannels/web/components/plugins/PikeShoppingCartPlugin";
import FormInputTextboxPlugin from "../paymentChannels/web/components/plugins/FormInputTextboxPlugin";
import ModalDetailPlugin from "../paymentChannels/web/components/plugins/ModalDetailPlugin";

import '@react-page/editor/lib/index.css';
import { Form } from "react-bootstrap";
import { Order, PaymentCardTypeEnum, PaymentType } from "../../models/Order";
import { TylerEagleCancel, TylerEagleRequest } from "../../models/TylerEagleIntegration";
import { CANCEL_ORDER_REQUEST, CANCEL_ORDER_SUCCESS, clearOrderAction, processOrderAction, PROCESS_ORDER_FAILURE, PROCESS_ORDER_REQUEST, PROCESS_ORDER_SUCCESS, updateOrderValidationErrors } from "../../redux/actions/orderManagement";
import { postTylerEagleCancel, POST_TYLER_EAGLE_CANCEL_REQUEST, POST_TYLER_EAGLE_CANCEL_SUCCESS, setTylerEagleResponseAction } from "../../redux/actions/tylerEagleIntegration";
import TylerEaglePOSRedirectPlugin from "../paymentChannels/web/components/plugins/TylerEaglePOSRedirectPlugin";
import ButtonPlugin from "../paymentChannels/web/components/plugins/ButtonPlugin";
import SpacerPlugin from "../paymentChannels/web/components/plugins/SpacerPlugin";
import CopyrightFooterPlugin from "../paymentChannels/web/components/plugins/FooterCopyrightPlugin";
import PaymentLogosPlugin from "../paymentChannels/web/components/plugins/PaymentLogosPlugin";
import InvoicePaymentPlugin from "../paymentChannels/web/components/plugins/InvoicePaymentPlugin";
import { setRedirectResponseAction } from "../../redux/actions/redirectIntegration";
import PrivacyPolicyTermsConditionFooterPlugin from "../paymentChannels/web/components/plugins/FooterPrivacyPolicyTermsConditions";
import HeroPlugin from "../paymentChannels/web/components/plugins/HeroPlugin";
import OrderLineCartPlugin from "../paymentChannels/web/components/plugins/OrderLineCartPlugin";
import DatePickerPlugin from "../paymentChannels/web/components/plugins/DatePickerPlugin";
import SelectPlugin from "../paymentChannels/web/components/plugins/SelectPlugin";
import CheckboxPlugin from "../paymentChannels/web/components/plugins/CheckboxPlugin";
import RadioButtonPlugin from "../paymentChannels/web/components/plugins/RadioButtonPlugin";
import CatalogPlugin from "../paymentChannels/web/components/plugins/CatalogPlugin";
import SessionLimiterPlugin from "../paymentChannels/web/components/plugins/SessionLimiterPlugin";

import { IntegrationType, PaymentChannel } from "../../models/Client";

import { GoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { sendErrorToastAction } from "../../redux/actions/toast";
import _ from "lodash";

export interface IParams {
    id: string
}

export interface IQuickPayPageProps {
    content: Content,
    paymentChannel: PaymentChannel,
    webApplication: WebApplication,
    order: Order,
    tylerEagleRequest: TylerEagleRequest,
    tylerEagleCancel: TylerEagleCancel,
    tylerEagleActionResult: IActionResult,
    errorMessage: string,
    actionResult: IActionResult,
    siteKey: string
}

const QuickPayPage = ({ webApplication, paymentChannel, content, order, tylerEagleRequest, tylerEagleCancel, tylerEagleActionResult, errorMessage, actionResult, siteKey }: IQuickPayPageProps) => {
    let actionToken = "QuickPayPage"
    const dispatch = useDispatch();
    const { id }: IParams = useParams();
    const [value, setValue] = useState<Value>();
    const [webPage, setWebPage] = useState<WebPage>();
    const [redirect, setRedirect] = useState<string>("");
    const [validated, setValidated] = useState<boolean>(false);
    const [tylerEagleFormRedirectReady, setTylerEagleFormRedirectReady] = useState<boolean>(false);
    const [pluginPropertyMap, setPluginPropertyMap] = useState<Map<string, any>>(new Map<string, any>());
    const [formState, setFormState] = useState<any>();

    const setRoute = (path: string, webApplicationName: string, deepLink?: any) => {
        const targetDeepLinkString = "/search"
        const isDeepLink = deepLink?.includes(path.replace(":id", webApplicationName) + targetDeepLinkString);
        const modifiedDeepLink = targetDeepLinkString + _.split(deepLink,targetDeepLinkString)[1];
        if(isDeepLink) { sessionStorage.setItem('deepLink',modifiedDeepLink)};
        return path.replace(":id", webApplicationName) + "?" + WebPageEnum.Landing;
    }

    let cardPlugin = CardPlugin as CellPlugin<unknown, unknown>;
    let releaseCardPlugin = ReleaseCardPlugin as CellPlugin<unknown, unknown>;
    let horizontalRulePlugin = HorizontalRulePlugin as CellPlugin<unknown, unknown>;
    let imagePlugin = image as CellPlugin<unknown, unknown>;
    let headerPlugin = HeaderPlugin as CellPlugin<unknown, unknown>;
    let formInputTextboxPlugin = FormInputTextboxPlugin as CellPlugin<unknown, unknown>;
    let plainTextPlugin = PlainTextPlugin as CellPlugin<unknown, unknown>;
    let navigationLinkPlugin = NavigationLinkPlugin as CellPlugin<unknown, unknown>;
    let paymentReferencePlugin = PaymentReferencePlugin as CellPlugin<unknown, unknown>;
    let tylerEaglePaymentReferencePlugin = TylerEaglePaymentReferencePlugin as CellPlugin<unknown, unknown>;
    let tylerEaglePOSRedirectPlugin = TylerEaglePOSRedirectPlugin as CellPlugin<unknown, unknown>;
    let paymentDetailsPlugin = PaymentDetailsPlugin as CellPlugin<unknown, unknown>;
    let paymentSummaryPlugin = PaymentSummaryPlugin as CellPlugin<unknown, unknown>;
    let multiSearchPlugin = MultiSearchPlugin as CellPlugin<unknown, unknown>;
    let multiSearchResultsPlugin = MultiSearchResultsPlugin as CellPlugin<unknown, unknown>;
    let nericSearchResultsPlugin = NericSearchResultsPlugin as CellPlugin<unknown, unknown>;
    let forsythSearchResultsPlugin = ForsythSearchResultsPlugin as CellPlugin<unknown, unknown>;
    let larimerSearchResultsPlugin = LarimerSearchResultsPlugin as CellPlugin<unknown, unknown>;
    let loganSearchResultsPlugin = LoganSearchResultsPlugin as CellPlugin<unknown, unknown>;
    let mathewsSearchResultsPlugin = MathewsSearchResultsPlugin as CellPlugin<unknown, unknown>;
    let stCharlesSearchResultsPlugin = StCharlesSearchResultsPlugin as CellPlugin<unknown, unknown>;
    let pikeSearchResultsPlugin = PikeSearchResultsPlugin as CellPlugin<unknown, unknown>;
    let itemShoppingCartPlugin = ItemShoppingCartPlugin as CellPlugin<unknown, unknown>;
    let stCharlesShoppingCartPlugin = StCharlesShoppingCartPlugin as CellPlugin<unknown, unknown>;
    let forsythShoppingCartPlugin = ForsythShoppingCartPlugin as CellPlugin<unknown, unknown>;
    let loganShoppingCartPlugin = LoganShoppingCartPlugin as CellPlugin<unknown, unknown>;
    let larimerShoppingCartPlugin = LarimerShoppingCartPlugin as CellPlugin<unknown, unknown>;
    let mathewsShoppingCartPlugin = MathewsShoppingCartPlugin as CellPlugin<unknown, unknown>;
    let pikeShoppingCartPlugin = PikeShoppingCartPlugin as CellPlugin<unknown, unknown>;
    let modalDetailPlugin = ModalDetailPlugin as  CellPlugin<unknown, unknown>;
    let accountDetailsPlugin = AccountDetailsPlugin as CellPlugin<unknown,unknown>;
    let billingDetailsPlugin = BillingDetailsPlugin as CellPlugin<unknown, unknown>;
    let buttonPlugin = ButtonPlugin as CellPlugin<unknown, unknown>;
    let spacerPlugin = SpacerPlugin as CellPlugin<unknown, unknown>;
    let copyrightFooterPlugin = CopyrightFooterPlugin as CellPlugin<unknown, unknown>;
    let paymentLogosPlugin = PaymentLogosPlugin as CellPlugin<unknown, unknown>;
    let invoicePaymentPlugin = InvoicePaymentPlugin as CellPlugin<unknown, unknown>;
    let privacyPolicyTermsConditionFooterPlugin = PrivacyPolicyTermsConditionFooterPlugin as CellPlugin<unknown, unknown>;
    let heroPlugin = HeroPlugin as CellPlugin<unknown, unknown>;
    let orderLineCartPlugin = OrderLineCartPlugin as CellPlugin<unknown, unknown>;
    let datePickerPlugin = DatePickerPlugin as CellPlugin<unknown, unknown>;
    let selectPlugin = SelectPlugin as CellPlugin<unknown, unknown>;
    let checkboxPlugin = CheckboxPlugin as CellPlugin<unknown, unknown>;
    let radioButtonPlugin = RadioButtonPlugin as CellPlugin<unknown, unknown>;
    let catalogPlugin = CatalogPlugin as CellPlugin<unknown, unknown>;
    let sessionLimiterPlugin = SessionLimiterPlugin as CellPlugin<unknown, unknown>;

    const cellPlugins = new Array<CellPlugin>();
    cellPlugins.push(imagePlugin);
    cellPlugins.push(headerPlugin);
    cellPlugins.push(cardPlugin);
    cellPlugins.push(releaseCardPlugin);
    cellPlugins.push(horizontalRulePlugin);
    cellPlugins.push(formInputTextboxPlugin);
    cellPlugins.push(plainTextPlugin);
    cellPlugins.push(navigationLinkPlugin);
    cellPlugins.push(paymentReferencePlugin);
    cellPlugins.push(tylerEaglePaymentReferencePlugin);
    cellPlugins.push(tylerEaglePOSRedirectPlugin);
    cellPlugins.push(paymentDetailsPlugin);
    cellPlugins.push(paymentSummaryPlugin);
    cellPlugins.push(accountDetailsPlugin);
    cellPlugins.push(billingDetailsPlugin);
    cellPlugins.push(multiSearchPlugin);
    cellPlugins.push(multiSearchResultsPlugin);
    cellPlugins.push(nericSearchResultsPlugin);
    cellPlugins.push(forsythSearchResultsPlugin);
    cellPlugins.push(larimerSearchResultsPlugin)
    cellPlugins.push(loganSearchResultsPlugin)
    cellPlugins.push(mathewsSearchResultsPlugin)
    cellPlugins.push(stCharlesSearchResultsPlugin);
    cellPlugins.push(pikeSearchResultsPlugin);
    cellPlugins.push(itemShoppingCartPlugin);
    cellPlugins.push(stCharlesShoppingCartPlugin);
    cellPlugins.push(forsythShoppingCartPlugin);
    cellPlugins.push(loganShoppingCartPlugin);
    cellPlugins.push(larimerShoppingCartPlugin);
    cellPlugins.push(mathewsShoppingCartPlugin);
    cellPlugins.push(pikeShoppingCartPlugin);
    cellPlugins.push(modalDetailPlugin);
    cellPlugins.push(buttonPlugin);
    cellPlugins.push(spacerPlugin);
    cellPlugins.push(copyrightFooterPlugin);
    cellPlugins.push(paymentLogosPlugin);
    cellPlugins.push(invoicePaymentPlugin);
    cellPlugins.push(privacyPolicyTermsConditionFooterPlugin);
    cellPlugins.push(heroPlugin);
    cellPlugins.push(orderLineCartPlugin);
    cellPlugins.push(datePickerPlugin);
    cellPlugins.push(selectPlugin);
    cellPlugins.push(checkboxPlugin);
    cellPlugins.push(radioButtonPlugin);
    cellPlugins.push(catalogPlugin);
    cellPlugins.push(sessionLimiterPlugin);

    const tylerEaglePaymentReferenceValue = (tylerEagleRequest: TylerEagleRequest) => {
        var value = {} as any;
        value.amount = Number.parseFloat(tylerEagleRequest.amount);
        value.itemReference = tylerEagleRequest.transactionID;
        value.description = tylerEagleRequest.description;
        return value;
    }

    useEffect(() => {
        if (webApplication) {
            let webPage = webApplication.webPages.filter(_ => _.webPageType === WebPageEnum.QuickPay || _.webPageType === WebPageEnum.Payment)[0];
            if (webPage) {
                if (webPage.contentId !== '00000000-0000-0000-0000-000000000000') {
                    dispatch(getContentAction(webPage.contentId, actionToken));
                } else {
                    //// TODO - need to hanlde, as there is content for page
                }
                setWebPage(webPage);
            }
        } else {
            ////redirect back to start and load the web application
            setRedirect(setRoute(Routes.WebApplicationStart.path, id,window?.location?.href));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [webApplication]);

    useEffect(() => {
        if (paymentChannel && paymentChannel.integrationType === IntegrationType[IntegrationType.TylerEagle] && tylerEagleRequest) {
            var _pluginPropertyMap = new Map<string, any>();
            _pluginPropertyMap.set(tylerEaglePaymentReferencePlugin.id, tylerEaglePaymentReferenceValue(tylerEagleRequest));
            _pluginPropertyMap.set(tylerEaglePOSRedirectPlugin.id, tylerEagleRequest.nexusRedirectUrl);
            setPluginPropertyMap(_pluginPropertyMap);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentChannel, tylerEagleRequest]);

    useEffect(() => {
        if (tylerEagleFormRedirectReady) {
            setTimeout(function () {
                let htmlFormElement = document!.getElementById("tylerEagleResponseForm")! as HTMLFormElement;
                htmlFormElement.submit();
            }, 250);
        }
    }, [tylerEagleFormRedirectReady]);

    const populateCMSValueWithData = (value: Value) => {
        value.rows?.map(populateCMSValueDataRow);
    }

    const populateCMSValueDataRow = (row: Row, index: number, rows: Array<Row>) => {
        row.cells.map((cell: Cell, index: number) => {
            if (cell.plugin && cell.dataI18n && pluginPropertyMap.has(cell.plugin.id)) {
                var defaultValue = cell.dataI18n.default as any;
                defaultValue.value = pluginPropertyMap.get(cell.plugin.id)!;
            }

            cell.rows?.map(populateCMSValueDataRow);
        });
    }

  useEffect(() => {
    if (content && webPage && content.msbId! === webPage.contentId) {
        for (let x = 0; x < content.revisions.length;x++) {
            if (content.revisions[x].statusEnum === StatusEnum.Draft) {
                let revision = content.revisions[x];
                let value = JSON.parse(revision.value);
                populateCMSValueWithData(value);
                setValue(value);
                break;
            }
        }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        setFormState(form);
        event.preventDefault();
        setValidated(false);

        let paymentType = parseInt(form?.elements?.paymentType?.value) as PaymentType;
        if (form.checkValidity() !== false && paymentType) {
            if (paymentType === PaymentType.ECheck) {
                let validationErrors: any = {};

                if (form.elements.eCheckRoutingNumber.value.length !== 9 || !form.elements.eCheckBankName.value) {
                    return;
                }

                var eCheckAccountNumber = form.elements.eCheckAccountNumber.value;
                var confirm_eCheckAccountNumber = form.elements.confirm_eCheckAccountNumber.value;

                if (!eCheckAccountNumber || eCheckAccountNumber.length === 0) {
                    validationErrors.eCheckAccountNumber_required = "Bank account number required.";
                }

                if (!confirm_eCheckAccountNumber || confirm_eCheckAccountNumber.length === 0) {
                    validationErrors.confirm_eCheckAccountNumber_required = "Confirm bank account Number required.";
                }

                if (!!validationErrors.eCheckAccountNumber_required || !!validationErrors.eCheckAccountNumber_required) {
                    dispatch(updateOrderValidationErrors(validationErrors, actionToken));
                    form.checkValidity();
                    //setValidated(true);
                    return;
                } else {
                    if (eCheckAccountNumber !== confirm_eCheckAccountNumber) {
                        validationErrors.eCheckAccountNumber_match = "Bank account number must match Confirm bank account number.";
                        validationErrors.confirm_eCheckAccountNumber_match = "Bank account number must match Confirm bank account number.";
                        dispatch(updateOrderValidationErrors(validationErrors, actionToken));
                        form.checkValidity();
                        //setValidated(true);
                        return;
                    } else {
                        dispatch(updateOrderValidationErrors({}, actionToken));
                    }
                }
            }

            if (paymentType === PaymentType.CreditCard) {
                if (!order.cardType) return;
            }

            if (paymentType === PaymentType.Terminal) {
                if (!order.cardType) return;
            }

            if (paymentType === PaymentType.ECheck) {
                order.nameOnCard = form.elements.eCheckAccountHolderName.value;
                order.eCheckRoutingNumber = form.elements.eCheckRoutingNumber.value;
                order.eCheckAccountNumber = form.elements.eCheckAccountNumber.value;
            } else if (paymentType === PaymentType.CreditCard) {
                order.nameOnCard = form.elements.nameOnCard.value;
                order.creditCardNumber = form.elements.cardNumber.value;
                order.expirationDate = form.elements.expirationDate.value;
                order.cvv = form.elements.cvv.value;
                order.isCardPresent = false;
            } else if (paymentType === PaymentType.Terminal) {
                paymentType = PaymentType.CreditCard;
                let ids = form?.elements?.terminalLaneId?.value;
                let fields = ids.split("|");
                order.laneId = fields.pop();
                order.terminalId = fields.pop();
                order.isCardPresent = true;
                if (PaymentCardTypeEnum[order.cardType].indexOf("Debit") >= 0) order.isDebitCard = true;
            }

            //order.referenceNumber = form.elements.referenceNumber.value;
            order.paymentType = paymentType;

            order.addressLine1 = form.elements.addressLine1.value;
            order.addressLine2 = form.elements.addressLine2.value;
            order.country = form.elements.country.value;
            order.city = form.elements.city.value;
            order.state = form.elements?.state?.value || '';
            order.zip = form.elements.zipCode.value.replace(/\s+/g, ' ');
            order.phone = form.elements.phoneNumber.value.replace(/[^0-9]/g, '');
            order.emailAddress = form.elements.emailAddress.value;

            //start:collect client metadata form elements
            let clientMetadatakKeyValues: any = {};
            var clientMetadataElements: HTMLInputElement[] = Array.prototype.slice.call(form.elements).filter(isClientMetadata);

            clientMetadataElements.forEach(x => {
                clientMetadatakKeyValues[x.id] = x.value;
            });

            if (tylerEagleRequest) {
                clientMetadatakKeyValues['tylerEagleRequest'] = tylerEagleRequest;
            }

            if (Object.keys(clientMetadatakKeyValues).length > 0) {
                order.metadata = JSON.parse(JSON.stringify(clientMetadatakKeyValues));
            }
            //end:collect client metadata form elements
            form.specialDisabled.disabled = true;

            setValidated(true);
            console.log("PAYMENT SUBMITTED...");

            refreshReCaptchaCount.current = 0;
            setRefreshReCaptcha(r => !r);
        }
    }

    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
    const refreshReCaptchaTimeout = useRef<ReturnType<typeof setTimeout>>();
    const refreshReCaptchaCount = useRef<number>(0);

    const handleReCaptchaVerify = useCallback((reCaptchaToken) => {
        console.log("TOKEN PROVIDED...", reCaptchaToken.length, validated, refreshReCaptchaCount.current);
        clearTimeout(refreshReCaptchaTimeout.current as unknown as number);
        if (validated) {
            if (reCaptchaToken.length > 500) {
                setTimeout(() => {
                    dispatch(processOrderAction(order, actionToken, reCaptchaToken));
                    setValidated(false);
                }, 2000);
            } else {
                if (refreshReCaptchaCount.current < 5) {
                    setTimeout(() => {
                        refreshReCaptchaCount.current += 1;
                        setRefreshReCaptcha(r => !r);
                    }, 2000);
                } else {
                    dispatch(sendErrorToastAction("Our fraud detection service encountered an issue. We may not be able to process your transaction. Try again later."));
                    formState.specialDisabled.disabled = false;
                    setValidated(false);
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshReCaptcha]);

    useEffect(() => {
        console.log("TOKEN REQUESTED...", validated);
        if (validated) {
            refreshReCaptchaTimeout.current = setTimeout(() => {
                dispatch(sendErrorToastAction("Our fraud detection service encountered an issue. We may not be able to process your transaction. Try again later."));
                formState.specialDisabled.disabled = false;
                setValidated(false);
            }, 10000);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshReCaptcha])

    function isClientMetadata(formElement: HTMLInputElement) {
        return formElement.id.startsWith('cmd_');
    }

    useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.type === PROCESS_ORDER_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === PROCESS_ORDER_SUCCESS) {
                    if (paymentChannel.integrationType === IntegrationType[IntegrationType.Redirect]) {
                        if (!dispatch(setRedirectResponseAction())) {
                            window.history.replaceState({ tabForId: "/" }, "", "/");
                            setRedirect(setRoute(Routes.ReceiptPage.path, webApplication.subdomain));
                        }
                    } else if (paymentChannel.integrationType === IntegrationType[IntegrationType.TylerEagle]) {
                        dispatch(setTylerEagleResponseAction());
                        setRedirect(setRoute(Routes.ReceiptPage.path, webApplication.subdomain));
                    } else {
                        setRedirect(setRoute(Routes.ReceiptPage.path, webApplication.subdomain));
                    }
                } else if (actionResult.result === PROCESS_ORDER_FAILURE) {
                    if (formState) formState.specialDisabled.disabled = false;
                    setValidated(false);
                }
            } else if (actionResult.type === CANCEL_ORDER_REQUEST && actionResult.result === CANCEL_ORDER_SUCCESS) {
                if (paymentChannel.integrationType === IntegrationType[IntegrationType.TylerEagle]) {
                    dispatch(postTylerEagleCancel(actionToken));
                } else {
                    setRedirect(setRoute(Routes.LandingPage.path, webApplication.subdomain));
                    dispatch(clearOrderAction());
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionResult]);

    function tylerEagleCancelRedirect() { return { __html: `<form id="tylerEagleResponseForm"  name="tylerEagleResponseForm" action='https://apps.douglas.co.us/treasurer/financeweb/externalPaymentFailed.jsp' method="POST"><textarea style="display:none" name="ResponseXML"><?xml version=\"1.0\" encoding=\"utf-8\"?><PaymentResponse><ErrorCode>11</ErrorCode><ErrorDescription>Transaction Cancelled</ErrorDescription></PaymentResponse></textarea></form>` }; };

    useEffect(() => {
        if (tylerEagleActionResult && tylerEagleActionResult.result) {
            if (tylerEagleActionResult.type === POST_TYLER_EAGLE_CANCEL_REQUEST) {
                if (tylerEagleActionResult.result === POST_TYLER_EAGLE_CANCEL_SUCCESS) {
                    setTylerEagleFormRedirectReady(true);
                }
            }
        }
    }, [tylerEagleActionResult]);

    if (redirect !== "") {
        return (<Redirect push to={redirect!} />)
    } else {
        return (
            <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
                <GoogleReCaptcha onVerify={handleReCaptchaVerify} refreshReCaptcha={refreshReCaptcha} />
                <div id="nonPrintable">
                    <Form validated={validated} onSubmit={handleSubmit}>
                        <Editor cellPlugins={cellPlugins} value={value} readOnly={true} />
                    </Form>
                </div>
                {tylerEagleCancel ?
                    <div dangerouslySetInnerHTML={tylerEagleCancelRedirect()} />
                    :
                    <></>
                }
            </GoogleReCaptchaProvider>
        )
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        webApplication: state.paymentChannelWebApplication.webApplication,
        paymentChannel: state.paymentChannelWebApplication.paymentChannel,
        content: state.cms.content,
        order: state.orderManagement.order,
        tylerEagleRequest: state.tylerEagleIntegration.tylerEagleRequest,
        tylerEagleCancel: state.tylerEagleIntegration.tylerEagleCancel,
        tylerEagleActionResult: state.tylerEagleIntegration.actionResult,
        actionResult: state.orderManagement.actionResult,
        siteKey: state.webAppSettings.siteKey,
        errorMessage: state.orderManagement.errorMessage
    };
};

export default connect(mapStateToProps)(QuickPayPage);

